<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="deleteItemDialog" :style="{width: '450px'}" header="Konfirmasi" :modal="true" class="p-fluid">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="item">Apakah anda yakin untuk menghapus  <b>{{item.sp_no}}</b> ini ?</span>
        </div>
        <template #footer>
            <Button label="Tidak" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Ya" icon="pi pi-check" class="p-button-text" @click="deletingItem" :loading="loading"/>
        </template>
    </Dialog>

</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,

            // edit
            deleteItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // EDIT
		hideDialog() {
			this.deleteItemDialog = false;
		},
		deletingItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/surat-promo/delete',
                data: {
                    "sp_id" : this.item.sp_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data berhasil dihapus', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.deleteItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data gagal dihapus', life: 3000,});
            });
		},
    }
}
</script>
<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>No. Surat Promo</label>
            <InputText v-model="item.sp_no" required="true" autofocus :class="{ 'p-invalid': errorEdit.sp_no }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.sp_no" >{{ errorEdit.sp_no[0] }}</small >
        </div>
        <div class="field">
            <label>Area</label>
            <Dropdown  dataKey="region_id" 
                    ref="dregion" v-model="item.region_id" 
                    :loading="loadingDropdownRegion" 
                    :options="dataDropdownRegion" 
                    :class="{ 'p-invalid': errorEdit.region_id }" 
                    optionLabel="region_name" optionValue="region_id" 
                    placeholder="Pilih Area" :filter="true" 
                    :showClear="true" @filter="searchDropdownRegion($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.region_id" >{{ errorEdit.region_id[0] }}</small>
        </div>
        <div class="field">
            <label>Start - End</label>
            <Calendar ref="cal" selectionMode="range"  :manualInput="false" v-model="period" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.product_code" >{{ errorEdit.product_code[0] }}</small >
        </div>
        <div class="field">
            <label>Kode Produk</label>
            <InputText v-model="item.product_code" required="true" autofocus :class="{ 'p-invalid': errorEdit.product_code }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.product_code" >{{ errorEdit.product_code[0] }}</small >
        </div>
        <div class="field">
            <label>Nama produk</label>
            <InputText v-model="item.product_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.product_name }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.product_name" >{{ errorEdit.product_name[0] }}</small >
        </div>
        <div class="field">
            <label>Kategory Sales</label>
            <Dropdown v-model="item.sales_category" :options="dataDropdownKategoriSales" optionLabel="name" optionValue="code" required="true" placeholder="Pilih Kategori" :class="{ 'p-invalid': errorEdit.sales_category }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.sales_category" >{{ errorEdit.sales_category[0] }}</small >
        </div>
        <div class="field">
            <label>Kode Promo</label>
            <Dropdown  dataKey="pt_id" 
                    ref="dkodepromo" v-model="item.promo_code" 
                    :loading="loadingDropdownKodePromo" 
                    :options="dataDropdownKodePromo" 
                    :class="{ 'p-invalid': errorEdit.promo_code }" 
                    optionLabel="pt_name" optionValue="pt_id" 
                    placeholder="Pilih Kode Promo" :filter="true" 
                    :showClear="true" @filter="searchDropdownKodePromo($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.promo_code" >{{ errorEdit.promo_code[0] }}</small>
        </div>
        <div class="field">
            <label>Minimum Order</label>
            <InputText type="number" v-model="item.required_order_qty" required="true" :min="0" autofocus :class="{ 'p-invalid': errorEdit.required_order_qty }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.required_order_qty" >{{ errorEdit.required_order_qty[0] }}</small >
        </div>
        <div class="field">
            <label>Ketentuan promo</label>
            <Textarea v-model="item.promo_desc" required="true" autofocus :class="{ 'p-invalid': errorEdit.promo_desc }" rows="5" cols="30"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.promo_desc" >{{ errorEdit.promo_desc[0] }}</small >
        </div>
        <div class="field">
            <label>Discount %</label>
            <InputText type="number" v-model="item.discount_percentage" required="true" :min="0" autofocus :class="{ 'p-invalid': errorEdit.discount_percentage }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.discount_percentage" >{{ errorEdit.discount_percentage[0] }}</small >
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import moment from 'moment';
import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownKodePromo: false,
            loadingDropdownRegion: false,

            // dataDropdown
            dataDropdownKodePromo: null,
            dataDropdownRegion: null,
            dataDropdownKategoriSales: [
                {name: 'TENDER', code: 'TENDER'},
                {name: 'NON TENDER', code: 'NON TENDER'},
            ],

            period: [new Date(), new Date()],

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.$store.commit('setErrorEdit', {});
                        this.period = this.item.date_start ? [new Date(this.item.date_start), new Date(this.item.date_end)] : [new Date(), new Date()];
                        this.searchDropdownKodePromo('', 'edit', this.item.master_promo_type ? this.item.master_promo_type.pt_name : null);
                        this.searchDropdownRegion('', 'edit', this.item.master_region ? this.item.master_region.region_name : null);
                    }
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownKodePromo(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dkodepromo.filterValue = valueEdit;
                }

                if (purpose == "edit") {
                    this.loadingDropdownKodePromo = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-promo-type',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "edit") {
                        this.dataDropdownKodePromo = res.data.data;
                        this.loadingDropdownKodePromo = false;
                    } else if (purpose == null) {
                        this.dataDropdownKodePromo = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownRegion(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dregion.filterValue = valueEdit;
                }

                if (purpose == "edit") {
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "edit") {
                        this.dataDropdownRegion = res.data.data;
                        this.loadingDropdownRegion = false;
                    } else if (purpose == null) {
                        this.dataDropdownRegion = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
             if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.loading = true;

                this.axios({
                    method: 'POST',
                    url: process.env.VUE_APP_ROOT_API + 'web/surat-promo/update',
                    data: {
                        "sp_id" : this.item.sp_id,
                        "sp_no" : this.item.sp_no,
                        "region_id" : this.item.region_id,
                        "date_start" : this.period_start_label,
                        "date_end" : this.period_end_label,
                        "product_code" : this.item.product_code,
                        "product_name" : this.item.product_name,
                        "sales_category" : this.item.sales_category,
                        "promo_code" : this.item.promo_code,
                        "promo_desc" : this.item.promo_desc,
                        "minimum_order" : this.item.minimum_order,
                        "discount_percentage" : this.item.discount_percentage,
                    },
                })
                .then((res) => {
                    this.loading = false;
                    if (res) {
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data berhasil diupdate', life: 3000,});
                        this.$store.commit('setErrors', {});
                        this.$store.commit('setErrorEdit', {});
                        // this.item = {};
                        this.editItemDialog = false;
                        this.$emit('submit');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data gagal diupdate', life: 3000,});
                });

            }else{
                 alert('Make sure the time range is correct or not empty');
            }
		},
    }
}
</script>